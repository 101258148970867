<template>
	<div class="bond_bill">
		<div class="el-content mb12">
			<a-space>
				<a-input-search style="width: 400px;" v-model:value="search.shop_id" placeholder="商家id" enter-button @search="getShopBailBillList(1,info.limit)" />
				<a-button type="primary" @click="show.addbill = true"><i class="ri-add-line ri-lg ri-top"></i>添加账单</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'店铺名称',dataIndex:'name',slots:{customRender:'name'}},
				{title:'描述',dataIndex:'option_desc'},
				{title:'金额',dataIndex:'amount',slots:{customRender:'amount'}},
				{title:'创建时间',dataIndex:'create_time'},
			]">
				<template #name="{record}">
					<div style="display: flex;align-items: center;">
						<a-avatar v-if="record.shop" :src="record.shop.logo"/>
						<span style="margin-left: 5px;"  v-if="record.shop">{{record.shop.name}}(shop_id:{{record.shop.shop_id}})</span>
					</div>
				</template>
				<template #amount="{record}">
					<span class="kdred">{{record.mode == 1 ?'+':'-'}}{{record.amount}}</span>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getShopBailBillList(info.page,e)}"
					@change="(e)=>{getShopBailBillList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="show.addbill" title="添加保证金账单" @ok="saveBillForm" @cancel="show.addbill = false" width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="店铺">
					<com-shop-search-select @change="e=>{billForm.shop_id = e}"></com-shop-search-select>
				</a-form-item>
				<a-form-item label="操作类型">
					<a-select v-model:value="billForm.option_type" style="width: 100%;" placeholder="操作类型">
						<a-select-option value="land_order">土地订单</a-select-option>
						<a-select-option value="adopt_order">认养订单</a-select-option>
						<a-select-option value="goods_order">商品订单</a-select-option>
						<a-select-option value="gold_order">积分订单</a-select-option>
						<a-select-option value="custom">操作员</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="操作描述">
					<a-input v-model:value="billForm.option_desc"></a-input>
				</a-form-item>
				<a-form-item label="订单号">
					<a-input v-model:value="billForm.option_pk"></a-input>
				</a-form-item>
				<a-form-item label="金额 ">
					<a-input v-model:value="billForm.amount"></a-input>
				</a-form-item>
				<a-form-item label="方式">
					  <a-radio-group v-model:value="billForm.mode">
					      <a-radio value="0">减少</a-radio>
					      <a-radio value="1">增加</a-radio>
					    </a-radio-group>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import comShopSearchSelect from '@/components/miniapp/com-shop-search-select.vue'
import { reactive, toRefs } from 'vue'
import shopModel from '@/api/addons/shop'
import { useRoute } from 'vue-router'
export default{
	components:{
		comShopSearchSelect
	},
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			search:{
				shop_id:'',
				order_sn:'',
				pay_sn:'',
				pay_type:'',
				pay_status:'',
				time:[],
			},
			show:{
				addbill:false,
			},
			billForm:{
				shop_id:'',
				option_type:'',
				option_desc:'',
				option_pk:0,
				amount:'',
				mode:'0'
			}
		})
		const opt = useRoute().query
		_d.search.shop_id = opt.shop_id ?? ""
		getShopBailBillList(1,_d.info.limit)
		function getShopBailBillList(page,limit){
			shopModel.getShopBondBill(page,limit,_d.search,res=>_d.info = { limit,...res})
		}

		const saveBillForm = ()=>shopModel.addShopBondBill(_d.billForm,()=>{
			_d.show.addbill = false
			_d.billForm.shop_id = '',
			_d.billForm.option_type = '',
			_d.billForm.option_desc = '',
			_d.billForm.option_pk = 0,
			_d.billForm.amount = '',
			_d.billForm.mode = '0'
			getShopBailBillList(_d.info.page,_d.info.limit)
		})

		return{
			...toRefs(_d),
			getShopBailBillList,
			saveBillForm
		}
	}
}
</script>

<style>
</style>
